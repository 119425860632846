import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  Popover,
  TextField,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { isPositiveNumber, isNumberOrMinus } from 'helpers/helper';
import M from 'messages';
import classes from './styles';

const CustomNumberRange = ({ field, onFilterCallback, filteredParams }) => {
  const memoizedFilterValue = useMemo(
    () => {
      if (field.isLiteral) {
        return {
          [`${field.id}_min_literal`]: filteredParams?.params?.filter?.[`${field.id}_min_literal`],
          [`${field.id}_max_literal`]: filteredParams?.params?.filter?.[`${field.id}_max_literal`],
        }
      }
      return {
        [`${field.id}_min`]: filteredParams?.params?.filter?.[`${field.id}_min`],
        [`${field.id}_max`]: filteredParams?.params?.filter?.[`${field.id}_max`],
      }
    }, [field.id, field.isLiteral, filteredParams?.params?.filter]
  );

  const [searchTerm, setSearchTerm] = useState(memoizedFilterValue);
  const [hasError, setHasError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setSearchTerm(memoizedFilterValue);
  }, [field, memoizedFilterValue]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearchTerm(memoizedFilterValue);
    setHasError(false);
    setAnchorEl(null);
  };

  const handleApply = (newValue = null) => {
    if (onFilterCallback) {
      handleClose();
      onFilterCallback(newValue || searchTerm);
    }
  };

  const handleClear = () => {
    const value = { [`${field.id}_${field.isLiteral ? 'min_literal' : 'min'}`]: '', [`${field.id}_${field.isLiteral ? 'max_literal' : 'max'}`]: '' };
    setSearchTerm(value);
    setHasError(false);
    handleApply(value)
  };

  const getLabel = useCallback((type) => M.get(`${field.label}${type}`), [field]);
  const memoizedMinLabel = useMemo(() => getLabel('Min'), [getLabel]);
  const memoizedMaxLabel = useMemo(() => getLabel('Max'), [getLabel]);

  const getFieldId = useCallback((type) => field.isLiteral ? `${field.id}_${type}_literal` : `${field.id}_${type}`, [field]);
  const memoizedFieldIdMin = useMemo(() => getFieldId('min'), [getFieldId]);
  const memoizedFieldIdMax = useMemo(() => getFieldId('max'), [getFieldId]);

  const getValuePath = (type) => {
    const valuePath = field.isLiteral ? filteredParams?.params?.filter?.[`${field.id}_${type}_literal`] : filteredParams?.params?.filter?.[`${field.id}_${type}`];
    return valuePath;
  };

  const getValue = () => {
    if ((+getValuePath('min') === +getValuePath('max')) === 0) {
      return '';
    }
    if (
      (filteredParams?.params?.filter?.[`${field.id}_min`] || filteredParams?.params?.filter?.[`${field.id}_max`]) ||
      (filteredParams?.params?.filter?.[`${field.id}_min_literal`] || filteredParams?.params?.filter?.[`${field.id}_max_literal`])
    ) {
      let value = '';
      value = getValuePath('min') ? `${M.get(field.label)} Min: ${getValuePath('min')}` : M.get(field.label);
      value = getValuePath('max') ? `${value} Max: ${getValuePath('max')}` : value;
      return value;
    }
    return '';
  };

  const validateRange = (newValue, fieldKey) => {
    if (
      (searchTerm[memoizedFieldIdMax] && fieldKey === memoizedFieldIdMin && newValue > +searchTerm[memoizedFieldIdMax]) ||
      (newValue && searchTerm[memoizedFieldIdMin] && fieldKey === memoizedFieldIdMax && newValue < +searchTerm[memoizedFieldIdMin])
    ) {
      setHasError(true);
      return;
    }
    setHasError(false);
  };

  const handleRangeInputChange = (newValue, fieldKey) => {
    setSearchTerm({ ...searchTerm, [fieldKey]: newValue });
    validateRange(newValue, fieldKey);
  };

  const validateInput = (value) => {
    if (field.isNegative) {
      return isNumberOrMinus(value);
    }
    return isPositiveNumber(value);
  }

  return (
    <Box sx={classes.box}>
      <FormControl margin="dense" fullWidth variant="outlined">
        <Box
          sx={{ ...classes.rangeInput, ...(getValue() && classes[field.selectedClassName]) }}
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
          tabIndex={0}
        >
          <Box sx={classes.valueText}>
            {getValue()
              ? <Box component='span' >{getValue()}</Box>
              : <Box sx={classes.placeholder}>{M.get(`${field.label}Range`)}</Box>
            }
          </Box>
          <Box sx={classes.arrowIcon}>
            {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </Box>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          slotProps={{ paper: { sx: classes.paper } }}
        >
          <Box sx={classes.popoverContainer}>
            <FormControl fullWidth variant="outlined">
              <TextField
                variant="standard"
                sx={{ ...classes.customTextField, '& .MuiInputLabel-root': { fontSize: 14 } }}
                size="small"
                placeholder={memoizedMinLabel}
                id={memoizedFieldIdMin}
                value={searchTerm?.[memoizedFieldIdMin] || ''}
                name={memoizedFieldIdMin}
                label={memoizedMinLabel}
                onChange={(event) => {
                  const { value } = event.target;
                  if (validateInput(value) || value === '') {
                    handleRangeInputChange(value, memoizedFieldIdMin);
                  }
                }}
                slotProps={{ htmlInput: { min: 0 } }} />
              <TextField
                variant="standard"
                sx={{ ...classes.customTextField, '& .MuiInputLabel-root': { fontSize: 14 } }}
                size="small"
                placeholder={memoizedMaxLabel}
                id={memoizedFieldIdMax}
                value={searchTerm?.[memoizedFieldIdMax] || ''}
                name={memoizedFieldIdMax}
                label={memoizedMaxLabel}
                onChange={(event) => {
                  const { value } = event.target;
                  if (validateInput(value) || value === '') {
                    handleRangeInputChange(value, memoizedFieldIdMax);
                  }
                }}
                slotProps={{ htmlInput: { min: 1 } }}
                error={hasError} />
              <Box sx={classes.actions}>
                <Button sx={classes.capitalize} onClick={handleClear}>
                  {M.get('actions.clear')}
                </Button>
                <Button disabled={hasError} sx={{ ...classes.primaryIconButton, ...classes.capitalize }} onClick={() => handleApply()} variant="contained" color="primary">
                  {M.get('actions.apply')}
                </Button>
              </Box>
            </FormControl>
          </Box>
        </Popover>
      </FormControl>
    </Box>
  );
};

CustomNumberRange.propTypes = {
  onFilterCallback: PropTypes.func.isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
};

export default CustomNumberRange;
