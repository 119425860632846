import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PageHeader from 'components/common/pageHeader';
import domainData from 'store/domainStore';
import { routes } from 'config';
import classes from './styles';

const Home = observer(() => {
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const currentUser = domainData.getCurrentUser();
  const quickLinks = [
    {
      title: "Subscriptions",
      description: "Explore our Wine List and EU Wine Label plans to find the perfect fit for your needs.",
      show: !isUserSuperAdmin,
      link: routes.subscriptions.path,
    },
    {
      title: "Partners",
      description: "Access and manage information about platform partners.",
      show: isUserSuperAdmin,
      link: routes.serviceProviders.path,
    },
    {
      title: "Profile",
      description: "Customize your public wine list showcased on our partners’ page. Add a description, highlight key offerings with feature tags, and link your website and reservation page.",
      withLink: 'https://sharevino.co/partners',
      linkKey: 'our partners’ page',
      show: !isUserSuperAdmin,
      link: routes.serviceProviderEdit.path.replace(':id', currentUser.serviceProviderId),
    },
    {
      title: "Users",
      description: "Manage user accounts and access relevant details.",
      show: isUserSuperAdmin,
      link: routes.users.path,
    },
    {
      title: "Wine Lists",
      description: "Manage your wine collection and associated functionalities. This includes adding wines, creating wine lists for default display or events, and analyzing performance via the dashboard.",
      show: true,
      subMenus: [
        {
          title: "Wines",
          description: "Create and manage wines to include in your wine lists for showcasing.",
          show: true,
          link: routes.catalogue.path,
        },
        {
          title: "Lists",
          description: "In this section, you can create a wine list from the wines you have built. Your first wine list serves as your public wine list on our website. Pro and Premium plans offer private event lists, accessible via QR codes but not visible on the website.",
          show: true,
          link: routes.kiosksMenus.path,
        },
        {
          title: "Dashboard",
          description: "Track visitor activity on your wine lists, view wine ratings, identify your most popular wines, and gain valuable insights to improve your offerings.",
          show: true,
          link: routes.dashboard.path,
        }
      ]
    },
    {
      title: "EU Wine Labels",
      description: "Create QR-based wine labels to provide customers with essential wine information while meeting EU standards.",
      show: true,
      subMenus: [
        {
          title: "Labels",
          description: "Design QR-enabled labels to comply with EU regulations and share essential wine information. Manage QR-based labels to provide detailed product information to customers.",
          show: true,
          link: routes.wineLabels.path,
        }
      ]
    },
    {
      title: "Account Details",
      description: "Manage your personal information.",
      note: '(Available in the dropdown menu on the right side of the header)',
      show: true,
      link: routes.profileEdit.path.replace(':id', currentUser.id),
    },
    {
      title: "Settings",
      description: "Customize your account preferences and configure platform-wide settings.",
      note: '(Available in the dropdown menu on the right side of the header)',
      show: isUserSuperAdmin,
      link: routes.settings.path,
    },
    {
      title: "Winelovers",
      description: "Manage and monitor wine enthusiasts' activities on the platform.",
      show: isUserSuperAdmin,
      subMenus: [
        {
          title: "Users",
          description: "View and manage wine enthusiasts' profiles and preferences.",
          show: true,
          link: routes.wineLovers.path,
        },
        {
          title: "Activities",
          description: "Track and analyze wine enthusiasts' interactions, ratings, and engagement with wines.",
          show: true,
          link: routes.wineLoversActivities.path,
        }
      ]
    }
  ];
  const drawMenuLInk = (row) => (
    <li key={row.title} style={classes.listItem}>
      <a style={{ ...classes.link, ...classes.boldText }} href={row.link} rel="noopener noreferrer">{row.title}: </a>
      <span style={classes.description}>
        {row.withLink ? <>
          {row.description.split(row.linkKey)[0]}
          <a style={{ ...classes.link, ...classes.boldText }} href={row.withLink} rel="noopener noreferrer" target="_blank">{row.linkKey} </a>
          {row.description.split(row.linkKey)[1]}
        </> : row.description}
      </span>
      {row.note && <span style={{ ...classes.description, ...classes.note }}>{row.note}</span>}
    </li>
  )
  return (
    <Box
      sx={classes.root}
    >
      <PageHeader generalPageTitle='Welcome to Sharevino' onlyGeneralPageTitle />
      <Typography sx={classes.title}>
        Welcome to Sharevino: Your Wine Journey Starts Here!
      </Typography>
      <Typography sx={classes.description} align='center' mt={1}>
        ShareVino offers two solutions tailored to wine producers, restaurants, merchants, event organizers, and other wine professionals:
      </Typography>
      <Grid container spacing={5} justifyContent="center" mt={6} mb={3}>
        <Grid size={{ xs: 10, md: 5 }} sx={classes.card}>
          <Typography sx={classes.wineManagement}>
            Wine Lists
          </Typography>
          <Typography sx={classes.description} mt={1}>
            Perfect for wineries, restaurants, event organizers, importers, and more—our Wine List solution makes it easy to create, manage, and showcase your wine portfolio. Curate, share, and display your wines effortlessly, while receiving valuable feedback and insights.
          </Typography>
          <ul>
            <li style={classes.listItem}><span style={classes.description}>Create and manage wines</span></li>
            <li style={classes.listItem}><span style={classes.description}>Add wines to QR wine lists for seamless access</span></li>
            <li style={classes.listItem}><span style={classes.description}>Pro and Premium plans include data analytics, multiple wines and wine lists, and the ClearRate feature</span></li>
          </ul>
        </Grid>
        <Grid size={{ xs: 10, md: 5 }} sx={classes.card}>
          <Typography sx={classes.wineManagement}>
            EU Wine Labels
          </Typography>
          <Typography sx={classes.description} mt={1}>
            Ensure full compliance with EU wine labeling regulations. The EU Wine Label solution guides you through every step, from ingredient details to nutritional declaration, helping you meet requirements while enhancing your wine&apos;s presentation.
          </Typography>
          <ul>
            <li style={classes.listItem}><span style={classes.description}>Helpful regulatory tooltips</span></li>
            <li style={classes.listItem}><span style={classes.description}>Complete ingredient and nutritional declaration management</span></li>
            <li style={classes.listItem}><span style={classes.description}>Simplified QR label creation and the “Activate for Market” feature for full compliance</span></li>
          </ul>
        </Grid>
      </Grid>
      <Divider />
      <Typography sx={classes.wineManagement} mt={3}>
        Platform Navigation
      </Typography>
      <Typography sx={classes.description} mt={1}>
        Explore the platform’s features to manage your wine portfolio and enhance your operations:
      </Typography>
      <ul>
        {quickLinks.map(row =>
          row.show
            ? row.subMenus?.length
              ? <li key={row.title} style={classes.listItem}>
                <span style={classes.boldText}>{row.title}: </span>
                <span style={classes.description}>{row.description}</span>
                <ul>
                  {row.subMenus.map(subRow => drawMenuLInk(subRow))}
                </ul>
              </li>
              : drawMenuLInk(row)
            : null
        )}
      </ul>
    </Box>
  );
});

export default Home;