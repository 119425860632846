import M from 'messages';

const channelOptions = [
  {
    value: 'google',
    label: M.get('table.wineLoversActivities.filter.channel.google'),
    title: M.get('table.wineLoversActivities.filter.channel.google')
  },
  {
    value: 'facebook',
    label: M.get('table.wineLoversActivities.filter.channel.facebook'),
    title: M.get('table.wineLoversActivities.filter.channel.facebook')
  }
];

const roleOptions = [
  {
    value: 'Wine Enthusiast',
    label: M.get('table.wineLoversActivities.filter.role.wineEnthusiast'),
    title: M.get('table.wineLoversActivities.filter.role.wineEnthusiast')
  },
  {
    value: 'Winery Professional',
    label: M.get('table.wineLoversActivities.filter.role.wineryProfessional'),
    title: M.get('table.wineLoversActivities.filter.role.wineryProfessional')
  },
  {
    value: 'Sommelier/Restaurant',
    label: M.get('table.wineLoversActivities.filter.role.sommelierRestaurant'),
    title: M.get('table.wineLoversActivities.filter.role.sommelierRestaurant')
  },
  {
    value: 'Event Organizer',
    label: M.get('table.wineLoversActivities.filter.role.eventOrganizer'),
    title: M.get('table.wineLoversActivities.filter.role.eventOrganizer')
  },
  {
    value: 'Importer/Distributor',
    label: M.get('table.wineLoversActivities.filter.role.importerDistributor'),
    title: M.get('table.wineLoversActivities.filter.role.importerDistributor')
  },
  {
    value: 'Retailer/Wine Shop',
    label: M.get('table.wineLoversActivities.filter.role.retailerWineShop'),
    title: M.get('table.wineLoversActivities.filter.role.retailerWineShop')
  },
  {
    value: 'Wine Journalist/Educator',
    label: M.get('table.wineLoversActivities.filter.role.wineJournalistEducator'),
    title: M.get('table.wineLoversActivities.filter.role.wineJournalistEducator')
  }
];

const interestOptions = [
  {
    value: 'discover',
    label: M.get('table.wineLoversActivities.filter.interests.discover'),
    title: M.get('table.wineLoversActivities.filter.interests.discover')
  },
  {
    value: 'rate',
    label: M.get('table.wineLoversActivities.filter.interests.rate'),
    title: M.get('table.wineLoversActivities.filter.interests.rate')
  },
  {
    value: 'save',
    label: M.get('table.wineLoversActivities.filter.interests.save'),
    title: M.get('table.wineLoversActivities.filter.interests.save')
  },
  {
    value: 'buy',
    label: M.get('table.wineLoversActivities.filter.interests.buy'),
    title: M.get('table.wineLoversActivities.filter.interests.buy')
  },
  {
    value: 'offers',
    label: M.get('table.wineLoversActivities.filter.interests.offers'),
    title: M.get('table.wineLoversActivities.filter.interests.offers')
  },
  {
    value: 'events',
    label: M.get('table.wineLoversActivities.filter.interests.events'),
    title: M.get('table.wineLoversActivities.filter.interests.events')
  },
  {
    value: 'learn',
    label: M.get('table.wineLoversActivities.filter.interests.learn'),
    title: M.get('table.wineLoversActivities.filter.interests.learn')
  },
  {
    value: 'share',
    label: M.get('table.wineLoversActivities.filter.interests.share'),
    title: M.get('table.wineLoversActivities.filter.interests.share')
  },
  {
    value: 'notSet',
    label: M.get('table.wineLoversActivities.filter.interests.notSet'),
    title: M.get('table.wineLoversActivities.filter.interests.notSet')
  }
];

const registrationTypeOptions = [
  {
    value: 'Individual',
    label: M.get('table.wineLoversActivities.filter.registrationType.individual'),
    title: M.get('table.wineLoversActivities.filter.registrationType.individual')
  },
  {
    value: 'Business',
    label: M.get('table.wineLoversActivities.filter.registrationType.business'),
    title: M.get('table.wineLoversActivities.filter.registrationType.business')
  },
  {
    value: 'notSet',
    label: M.get('table.wineLoversActivities.filter.interests.notSet'),
    title: M.get('table.wineLoversActivities.filter.interests.notSet')
  }
];

const activityTypeOptions = [
  {
    value: 'clickedBuy',
    label: M.get('table.wineLoversActivities.filter.activityType.clickedBuy'),
    title: M.get('table.wineLoversActivities.filter.activityType.clickedBuy')
  },
  {
    value: 'clickedMore',
    label: M.get('table.wineLoversActivities.filter.activityType.clickedMore'),
    title: M.get('table.wineLoversActivities.filter.activityType.clickedMore')
  },
  {
    value: 'rate',
    label: M.get('table.wineLoversActivities.filter.activityType.rate'),
    title: M.get('table.wineLoversActivities.filter.activityType.rate')
  },
  {
    value: 'bookmark',
    label: M.get('table.wineLoversActivities.filter.activityType.bookmark'),
    title: M.get('table.wineLoversActivities.filter.activityType.bookmark')
  },
  {
    value: 'unBookmark',
    label: M.get('table.wineLoversActivities.filter.activityType.unBookmark'),
    title: M.get('table.wineLoversActivities.filter.activityType.unBookmark')
  }

];

const marketingToggleOptions = [
  {
    value: 'true',
    title: M.get('table.wineLoversActivities.filter.marketingToggle.yes')
  },
  {
    value: 'false',
    title: M.get('table.wineLoversActivities.filter.marketingToggle.no')
  }
];


export {
  channelOptions,
  roleOptions,
  interestOptions,
  registrationTypeOptions,
  activityTypeOptions,
  marketingToggleOptions
};