import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import qs from 'qs';

import CustomTable from 'components/common/customTableV2';
import EmptyData from 'components/common/emptyData';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import WineLoversActivitiesService from 'services/wineLoversActivitiesService';
import WineLoversActivitiesStore from 'store/wineLoversActivitiesStore';
import { adaptWineLoverActivitiesTableData } from 'helpers/adapter';
import { replacePath, getMessage } from 'helpers/helper';
import { ReactComponent as EmptyUser } from 'assets/emptyUser.svg';
import M from 'messages'

import { routes } from 'config';
import tableOptions from './config/config';
import CustomTableToolbar from './toolbar';
import classes from './styles';

const WineLoversActivities = observer(({ path }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [loading, setLoading] = useState(true);
  const [fromFilter, setFromFilter] = useState(true);
  const [wineLoversActivitiesData, setWineLoversActivitiesData] = useState({});

  const filteredParamsStore = WineLoversActivitiesStore.filter;
  const { searchFields, filterFields } = tableOptions.wineLoversActivities;

  // NOTE: cut / symbol
  const pageItem = path.substring(1);

  const setFilter = (filter) => {
    replacePath(navigate, filter);
    WineLoversActivitiesStore.setFilter(filter);
  };

  const queryParams = {
    params: {
      sort: pageParams?.sort || filteredParamsStore.params.sort,
      filter: pageParams?.filter || filteredParamsStore.params.filter,
      limit: pageParams?.limit || filteredParamsStore.params.limit,
      skip: pageParams?.skip || filteredParamsStore.params.skip,
      search: pageParams?.search || filteredParamsStore.params.search,
    }
  }


  const getWineLoversActivitiesLists = useCallback(
    async (params, withFilter = true) => {
      try {
        // setLoading(true);
        setFromFilter(withFilter);
        const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (urlParams?.filter && Object.keys(urlParams.filter).length) {
          setFromFilter(true);
        }
        replacePath(navigate, params);
        WineLoversActivitiesStore.setFilter(params);
        const newParams = { params: { ...params.params } };
        const response = await WineLoversActivitiesService.getWineLoversActivities(newParams);
        if (response) {
          WineLoversActivitiesStore.setStoreWineLoversActivities(response.data);
          setWineLoversActivitiesData({
            count: response.data.count,
            data: adaptWineLoverActivitiesTableData(response.data.data),
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  useEffect(() => {
    getWineLoversActivitiesLists(queryParams, false);

    return () => {
      WineLoversActivitiesStore.setFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchCallback = (value) => {
    let oldValue = queryParams.params?.search?.value;
    if (oldValue === undefined) {
      oldValue = '';
    }
    if (searchFields && (oldValue !== value)) {
      const newFilter = { params: { ...queryParams.params, search: { ...queryParams.params.search, value, fields: searchFields }, skip: 0 } }
      if (!value) {
        delete newFilter.params.search;
      }
      setFilter(newFilter);
      getWineLoversActivitiesLists(newFilter, true)
    }
  };

  const onFilterCallback = (filterObj) => {
    if (filterObj && !Array.isArray(filterObj)) {
      const fieldKeyList = Object.keys(filterObj);
      let newFilter = {};
      fieldKeyList.forEach(i => {
        const fieldValue = filterObj[i];
        let oldValue = queryParams.params?.filter?.[i];
        if (oldValue === undefined) {
          oldValue = '';
        }
        if (filterFields && (oldValue !== fieldValue) && fieldValue !== undefined) {
          newFilter = { ...newFilter, params: { ...queryParams.params, ...newFilter.params, filter: { ...queryParams.params.filter, ...newFilter?.params?.filter, [i]: fieldValue }, skip: 0 } }
        }
      });
      if (Object.keys(newFilter).length) {
        setFilter(newFilter);
        getWineLoversActivitiesLists(newFilter);
      }
    }
  };

  const handleExport = async () => {
    const { params } = WineLoversActivitiesStore.filter;
    delete params.limit;
    delete params.skip;
    WineLoversActivitiesService.exportWineLoversActivities(params);
  }

  const toolbarView = (
    <CustomTableToolbar
      onSearchCallback={onSearchCallback}
      filteredParams={queryParams}
      onFilterCallback={onFilterCallback}
      filterFields={filterFields}
      handleExport={wineLoversActivitiesData.count > 0 ? handleExport : null}
    />
  );

  const openPath = (row) => routes.wineLoverActivity.path.replace(':id', row.row_id); 

  const isEmptyState = () => (!fromFilter && !(wineLoversActivitiesData?.data?.length));

  if (loading) { return (<Loading />); }

  return (
    <>
      <Box sx={classes.root}>
        <PageHeader
          title={M.get('wineLoversActivities.pageTitle')}
          onlyGeneralPageTitle
        />
        <Grid size={{ xs: 12 }}>
          <Box sx={classes.tableRoot}>
            {isEmptyState() && !loading ? (
              <EmptyData
                icon={<EmptyUser />}
                title={pageItem}
                titleClassName="userEmptyPageTitle"
                mainClassName="userMain"
                withCreate={false}
              />
            ) : (
              <Grid container sx={classes.container}>
                <Box sx={classes.tabsContainer}>
                  <CustomTable
                    tableSources={wineLoversActivitiesData}
                    tableOptions={tableOptions.wineLoversActivities}
                    loading={loading}
                    filteredParams={queryParams}
                    setFilteredParams={setFilter}
                    getDataOnChange={getWineLoversActivitiesLists}
                    openPath={openPath}
                    withEditAction={false}
                    withCheckbox={false}
                    rowIsNotSelectable
                    toolbarView={toolbarView}
                    tableContainerClass="overflowHidden"
                  />
                </Box>
              </Grid>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  )
});

WineLoversActivities.propTypes = {
  path: PropTypes.string.isRequired
}

export default WineLoversActivities;