import { get } from 'services/client';
import { apiEndpoints, serverUrl } from 'config';

export default {
  getWineLovers: async (params) => {
    const options = { url: apiEndpoints.wineLovers, params: { ...params } };
    return get(options);
  },
  getWineLover: async (id) => {
    const options = { url: apiEndpoints.wineLover.replace(':id', id) };
    return get(options);
  },
  exportWineLovers: async (params) => {
    window.open(`${serverUrl}/api${apiEndpoints.exportWineLovers}?params=${JSON.stringify(params)}`, '_blank');
    return null;
  }
}