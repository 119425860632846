import { channelOptions, roleOptions, interestOptions, registrationTypeOptions, activityTypeOptions, marketingToggleOptions } from 'constants/winlover-filters';
import WineLoversActivitiesService from 'services/wineLoversActivitiesService';
import { adaptWineLoversActivitiesPartnerFilter, adaptWineLoversActivitiesMenuFilter, adaptWineLoversActivitiesWineFilter } from 'helpers/adapter';
import UserName from "../commonCellComponent/userName/UserName";

const tableOptions = {
  wineLoversActivities: {
    fields: [

      { id: 'user_name', label: 'table.wineLoversActivities.user', component: UserName, type: 'customComponent', sortable: true, width: '10%', cellPaddingLeft: '24px' },
      { id: 'user_id', label: 'table.wineLoversActivities.id', type: 'text', sortable: true, width: '5%', textAlign: 'right', headAlign: 'right' },
      { id: 'channel', label: 'table.wineLoversActivities.provider', type: 'text', sortable: true, width: '5%' },
      { id: 'role', label: 'table.wineLoversActivities.userType', type: 'text', sortable: true, width: '7%' },
      { id: 'registration_type', label: 'table.wineLoversActivities.registrationType', type: 'text', sortable: true, width: '6%' },
      { id: 'interests', label: 'table.wineLoversActivities.interests', type: 'text', sortable: true, width: '12%' },
      { id: 'marketing_enabled', label: 'table.wineLoversActivities.marketingToggle', type: 'text', sortable: true, width: '7%' },
      { id: 'activity_timestamp', label: 'table.wineLoversActivities.activityDateTime', type: 'text', sortable: true, width: '7%', textAlign: 'right', headAlign: 'right' },
      { id: 'activity_type', label: 'table.wineLoversActivities.activityType', type: 'text', sortable: true, width: '6%' },
      { id: 'partner_name', label: 'table.wineLoversActivities.partnerName', type: 'text', sortable: true, width: '9%' },
      { id: 'menu_name', label: 'table.wineLoversActivities.wineList', type: 'text', sortable: true, width: '9%' },
      { id: 'wine_name', label: 'table.wineLoversActivities.wineName', type: 'text', sortable: true, width: '9%' },
      { id: 'rating', label: 'table.wineLoversActivities.rating', type: 'text', sortable: true, width: '5%', textAlign: 'right', headAlign: 'right', cellPaddingRight: '24px' },
    ],
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    filterFields: [
      { id: 'channel', label: 'table.wineLoversActivities.filter.channel.title', type: 'custom-multi-autocomplete', options: channelOptions, withoutParams: true, priority: 1, optionsType: 'string', optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true },
      { id: 'role', label: 'table.wineLoversActivities.filter.role.title', type: 'custom-multi-autocomplete', options: roleOptions, withoutParams: true, priority: 1, optionsType: 'string', optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true },
      { id: 'partner_name', label: 'table.wineLoversActivities.filter.partner', type: 'custom-multi-autocomplete', options: WineLoversActivitiesService.getWineLoversActivitiesPartnerFilter, adapterCallback: adaptWineLoversActivitiesPartnerFilter, withoutParams: true, priority: 1, optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true },
      { id: 'menu_name', label: 'table.wineLoversActivities.filter.wineList', type: 'custom-multi-autocomplete', options: WineLoversActivitiesService.getWineLoversActivitiesMenuFilter, adapterCallback: adaptWineLoversActivitiesMenuFilter, withoutParams: true, priority: 1, optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true },
      { id: 'wine_name', label: 'table.wineLoversActivities.filter.wineName', type: 'custom-multi-autocomplete', options: WineLoversActivitiesService.getWineLoversActivitiesWineFilter, adapterCallback: adaptWineLoversActivitiesWineFilter, withoutParams: true, priority: 1, optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true },
      { id: 'registration_type', label: 'table.wineLoversActivities.filter.registrationType.title', type: 'custom-multi-autocomplete', options: registrationTypeOptions, withoutParams: true, priority: 1, optionsType: 'string', optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true },
      { id: 'interests', label: 'table.wineLoversActivities.filter.interests.title', type: 'custom-multi-autocomplete', options: interestOptions, withoutParams: true, priority: 1, optionsType: 'string', optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true },
      { id: 'marketing_enabled', label: 'table.wineLoversActivities.filter.marketingToggle.title', type: 'select', options: marketingToggleOptions, priority: 1, optionsWithCheckbox: false, selectedClassName: 'colorizeSelected' },
      { id: 'activity_type', label: 'table.wineLoversActivities.filter.activityType.title', type: 'custom-multi-autocomplete', options: activityTypeOptions, withoutParams: true, priority: 1, optionsType: 'string', optionsWithCheckbox: true, selectedClassName: 'colorizeSelected', sortBySelected: true },
      { id: 'rating', label: 'table.wineLoversActivities.filter.rating.title', type: 'numberRange', priority: 1, selectedClassName: 'colorizeSelected' },
      { id: 'activity_timestamp', label: 'table.wineLoversActivities.filter.activityDateTime.title', type: 'dateRange', priority: 1, selectedClassName: 'colorizeSelected' },
    ],
    searchFields: ['user_name', 'user_email']
  }
};

export default tableOptions;