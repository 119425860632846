import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Card,
  CardContent,
  Box,
  Avatar,
  Divider,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import SidebarStore from 'store/ui/sidebarStore';
import WineLoversActivitiesService from 'services/wineLoversActivitiesService';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import { adaptWineLoverActivityDetailsData } from 'helpers/adapter';
import { getMessage } from 'helpers/helper';
import M from 'messages';

import classes from './styles';

const WineLoverActivity = observer(() => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState({});
  const isSidebarOpened = SidebarStore.sidebarState;

  const getActivityData = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await WineLoversActivitiesService.getWineLoverActivity(id);
        setActivityData(adaptWineLoverActivityDetailsData(response.data));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, id]
  );

  useEffect(() => {
    getActivityData();
  }, [getActivityData]);

  const detailsOptions = [
    {
      message: M.get('wineLoversActivities.details.userName'),
      value: activityData.user_name
    },
    {
      message: M.get('wineLoversActivities.details.userEmail'),
      value: activityData.user_email
    },
    {
      message: M.get('wineLoversActivities.details.userId'),
      value: activityData.user_id
    },
    {
      message: M.get('wineLoversActivities.details.channel'),
      value: activityData.channel
    },
    {
      message: M.get('wineLoversActivities.details.role'),
      value: activityData.role
    },
    {
      message: M.get('wineLoversActivities.details.registrationType'),
      value: activityData.registration_type
    },
    {
      message: M.get('wineLoversActivities.details.interests'),
      value: activityData.interests
    },
    {
      message: M.get('wineLoversActivities.details.marketingEnabled'),
      value: activityData.marketing_enabled
    },
    {
      message: M.get('wineLoversActivities.details.activityTimestamp'),
      value: activityData.activity_timestamp
    },
    {
      message: M.get('wineLoversActivities.details.activityType'),
      value: activityData.activity_type
    },
    {
      message: M.get('wineLoversActivities.details.partnerName'),
      value: activityData.partner_name
    },
    {
      message: M.get('wineLoversActivities.details.menuName'),
      value: activityData.menu_name
    },
    {
      message: M.get('wineLoversActivities.details.wineName'),
      value: activityData.wine_name
    },
    {
      message: M.get('wineLoversActivities.details.rating'),
      value: activityData.rating
    },
  ];

  if (loading) return <Loading />;

  return (
    <Box
      sx={{
        ...classes.root,
        ...(isSidebarOpened && classes.rootDrawerOpen),
        ...(!isSidebarOpened && classes.rootDrawerClose)
      }}
    >
      <PageHeader
        generalPageTitle={`${M.get('wineLoversActivities.pageTitle')} | ${activityData.user_name || ''}`}
        onlyGeneralPageTitle
      />
      <Card sx={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Box sx={classes.avatar}>
            <Avatar src={activityData.user_image} sx={classes.avatarIcon}>
              {activityData.user_name?.charAt(0)}
            </Avatar>
          </Box>
          <Box>
            <Grid container sx={classes.detailsContainer}>
              {detailsOptions.map((detail, index) => (
                <Grid key={index} spacing={1} size={{ xs: 12, sm: 10, md: 9, lg: 7 }} sx={classes.fields}>
                  <Grid size={{ sm: 5, xs: 12 }}>
                    <Typography sx={classes.fieldName}>
                      {detail.message}
                    </Typography>
                  </Grid>
                  <Grid size={{ sm: 7, xs: 12 }}>
                    <Typography
                      sx={{
                        ...classes.fieldValue
                      }}
                    >
                      {detail.value}
                    </Typography>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default WineLoverActivity; 