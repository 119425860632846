import { routes } from 'config';
import Roles from 'constants/roles';

const getSPRoute = ({ isUserSuperAdmin, domainData }) => {
  const currentUser = domainData.getCurrentUser();
  let spPath = routes.serviceProviders.path;
  if (currentUser && !isUserSuperAdmin) {
    spPath = routes.serviceProviderEdit.path.replace(':id', currentUser.serviceProviderId);
  }
  return spPath;
}

const sideBarData = [
  { id: 1, label: "pages.welcome", link: routes.welcome.path, icon: 'home', iconPosition: 'start', height: 40, roles: Roles.welcome.access },
  { id: 2, label: "pages.subscriptions", link: routes.subscriptions.path, icon: 'subscriptions', iconPosition: 'start', height: 40, roles: Roles.subscriptions.access, showSideBarSection: ({ isUserSuperAdmin }) => !isUserSuperAdmin },
  { id: 3, label: "pages.sp", link: getSPRoute, icon: 'group', iconPosition: 'start', height: 40, roles: Roles.partner.access, showSideBarSection: ({ isUserSuperAdmin }) => isUserSuperAdmin },
  { id: 4, label: "pages.spSettings", link: getSPRoute, icon: 'group', iconPosition: 'start', height: 40, roles: Roles.partner.access, showSideBarSection: ({ isUserSuperAdmin }) => !isUserSuperAdmin },
  { id: 5, label: "pages.users", link: routes.users.path, icon: 'menu_book', iconPosition: 'start', height: 40, roles: Roles.users.access },
  {
    id: 6, name: "catalog", label: "pages.wineLovers", height: 40, show: ({isSidebarOpened}) => isSidebarOpened, roles: Roles.catalog.access, isParent: true,
    subMenu: [
      { id: 1, label: "pages.wineLoversUsers", link: routes.wineLovers.path, icon: 'group', iconPosition: 'start', height: 40, roles: Roles.wineLovers.access },
      { id: 2, label: "pages.wineLoversActivities", link: routes.wineLoversActivities.path, icon: 'analytics', iconPosition: 'start', height: 40, roles: Roles.wineLoversActivities.access },
    ]
  },
  {
    id: 7, name: "catalog", label: "pages.wineManagement", height: 40, show: ({isSidebarOpened}) => isSidebarOpened, roles: Roles.catalog.access, isParent: true,
    subMenu: [
      { id: 1, label: "pages.catalog", link: routes.catalogue.path, icon: 'liquor', iconPosition: 'start', height: 40, roles: Roles.catalog.access},
      { id: 2, label: "pages.kiosksMenus", link: routes.kiosksMenus.path, icon: 'menu_book', iconPosition: 'start', height: 40, roles: Roles.kiosksMenus.access },
      { id: 3, label: "pages.dashboard", link: routes.dashboard.path, icon: 'dashboard_customize', iconPosition: 'start', height: 40, roles: Roles.dashboardMenu.access },
    ]
  },
  {
    id: 8, name: "eu-label", label: "pages.wineLabels", height: 40, show: ({isSidebarOpened}) => isSidebarOpened, roles: Roles.catalog.access, isParent: true,
    subMenu: [
      { id: 1, label: "pages.labels", link: routes.wineLabels.path, icon: 'label', iconPosition: 'start', height: 40, roles: Roles.wineLabels.access },
    ]
  },
];

export default sideBarData;
