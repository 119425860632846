import M from 'messages';
import moment from 'moment';

import { pad } from './helper';

const chanelMapping = {
  'facebook': M.get('table.wineLoversActivities.filter.channel.facebook'),
  'google': M.get('table.wineLoversActivities.filter.channel.google'),
};
const marketingMapping = {
  'true': M.get('table.wineLoversActivities.filter.marketingToggle.yes'),
  'false': M.get('table.wineLoversActivities.filter.marketingToggle.no'),
};
const activityTypeMapping = {
  'clickedBuy': M.get('table.wineLoversActivities.filter.activityType.clickedBuy'),
  'clickedMore': M.get('table.wineLoversActivities.filter.activityType.clickedMore'),
  'rate': M.get('table.wineLoversActivities.filter.activityType.rate'),
  'bookmark': M.get('table.wineLoversActivities.filter.activityType.bookmark'),
  'unBookmark': M.get('table.wineLoversActivities.filter.activityType.unBookmark'),
};

const interestsMapping = {
  'discover': M.get('table.wineLoversActivities.filter.interests.discover'),
  'rate': M.get('table.wineLoversActivities.filter.interests.rate'),
  'save': M.get('table.wineLoversActivities.filter.interests.save'),
  'buy': M.get('table.wineLoversActivities.filter.interests.buy'),
  'offers': M.get('table.wineLoversActivities.filter.interests.offers'),
  'events': M.get('table.wineLoversActivities.filter.interests.events'),
  'learn': M.get('table.wineLoversActivities.filter.interests.learn'),
  'share': M.get('table.wineLoversActivities.filter.interests.share')
};

const createUsersTableData = (id, name, usersRole, email, isApproved, lastLoginDate, serviceProviderId, image) => ({ id, name, usersRole, email, isApproved, lastLoginDate, serviceProviderId, image });

const adaptUsersTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    dataList.push(
      createUsersTableData(
        pad(item.id, 3),
        item.name,
        M.get(`usersRoles.${item.usersRole.name}`),
        item.email,
        item.isApproved,
        item.lastLoginDate ? moment(item.lastLoginDate).format('DD.MM.YYYY, HH:mm') : '',
        item.serviceProvider ? item.serviceProvider.name : '',
        item.image
      )
    );
  });
  return dataList;
};

// eslint-disable-next-line camelcase
const createSPTableData = (id, sortingOrder, name, type, isQrPartner, isApproved, approved, country, email, userId, created_at) => ({ id, sortingOrder, name, type, isQrPartner, isApproved, approved, country, email, userId, created_at });

const adaptSPTableData = (response) => {
  const dataList = [];
  response.forEach((item) => {
    dataList.push(
      createSPTableData(
        pad(item.id, 3),
        item.sortingOrder,
        item.name,
        item.type,
        item.isQrPartner,
        item.user?.isApproved,
        item.user?.isApproved ? 'Approved' : 'Not approved',
        item.country?.name || '',
        item.user?.email || '',
        item.user?.id,
        item.created_at ? moment(item.created_at).format('DD/MM/YY') : '',
      )
    );
  });
  return dataList;
};

const createKiosksMenusTableData = (menuName, menuItemsCount, kiosksCount, serviceProviderId, id, isDefault, active, shouldResetWineRating, createdAt) => ({ menuName, menuItemsCount, kiosksCount, serviceProviderId, id, isDefault, active, shouldResetWineRating, createdAt });

const adaptKiosksMenusTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    dataList.push(
      createKiosksMenusTableData(
        item.menuName,
        item.menuItemsCount,
        (item.kiosksCount || 0) + (item.deliveryStationsCount || 0) + (item.chestFreezersCount || 0),
        item.name,
        pad(item.id, 3),
        item.isDefault,
        item.active,
        item.shouldResetWineRating,
        item.createdAt ? moment(item.createdAt).format('DD/MM/YY') : '',
      )
    );
  });
  return dataList;
};

const createCataloguesTableData = (id, partnerName, producer, name, country, color, isSuperVino, year, active, tableOrWineNumber, createdAt, serviceProviderId) => ({ id, partnerName, producer, name, country, color, isSuperVino, year, active, tableOrWineNumber, createdAt, serviceProviderId });

const adaptCataloguesTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    dataList.push(
      createCataloguesTableData(
        pad(item.id, 3),
        item.serviceProvider.name,
        item.producer,
        item.name,
        item.country?.name || '',
        item.color,
        item.isSuperVino,
        item.year,
        item.active,
        (item.useTableOrWineNumber && item.tableOrWineNumber) || 'N/A',
        item.createdAt ? moment(item.createdAt).format('DD/MM/YY') : '',
        item.serviceProviderId
      )
    );
  });
  return dataList;
};

const serviceProvidersOptionData = (data) => data.map(item => ({ value: item.id, label: item.name, title: item.name }));

const createWineLabelsTableData = (id, serviceProviderId, name, country, producer, color, year, energyKcal, status, createdAt, active, partnerName, partnerIsActive) => ({ id, serviceProviderId, name, country, producer, color, year, energyKcal, status, createdAt, active, partnerName, partnerIsActive });

const adaptWineLabelsTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    dataList.push(
      createWineLabelsTableData(
        pad(item.id, 3),
        item.serviceProvider.id,
        item.name,
        item.country?.name || '',
        item.producer,
        item.color,
        item.year,
        item.energyKcal,
        item.status === 'in_market' ? 'yes' : 'no',
        item.createdAt ? moment(item.createdAt).format('DD/MM/YY') : '',
        item.active,
        item.serviceProvider.name,
        item.serviceProvider.accountStatus === 'active'
      )
    );
  });
  return dataList;
};

const createWineLoversTableData = (id, name, email, image, provider, country, userType, registrationType, interests, receiveMarketingEmails, lastLogin, createdAt) => ({ id, name, email, image, provider, country, userType, registrationType, interests, receiveMarketingEmails, lastLogin, createdAt });

const adaptWineLoversTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    const formattedInterests = item.interests ? item.interests.split(',').map(interest => interestsMapping[interest.trim()] || interest.trim()).join('\n') : '';
    dataList.push(
      createWineLoversTableData(
        pad(item.id, 3),
        item.name,
        item.email,
        item.image,
        chanelMapping[item.provider] || item.provider,
        item.country,
        item.userType,
        item.registrationType,
        formattedInterests,
        marketingMapping[item.receiveMarketingEmails] || '',
        item.lastLogin ? moment(item.lastLogin).format('DD.MM.YYYY, HH:mm') : '',
        item.createdAt ? moment(item.createdAt).format('DD.MM.YYYY, HH:mm') : '',
      )
    );
  });
  return dataList;
};

const adaptWineLoverDetailsData = (data) => ({
  id: pad(data.id, 3),
  name: data.name,
  email: data.email,
  image: data.image,
  provider: chanelMapping[data.provider] || data.provider,
  country: data.country,
  lastLogin: data.lastLogin ? moment(data.lastLogin).format('DD.MM.YYYY, HH:mm') : '',
  createdAt: data.createdAt ? moment(data.createdAt).format('DD.MM.YYYY, HH:mm') : '',
  userType: data.userType,
  registrationType: data.registrationType,
  interests: data.interests ? data.interests.split(',').map(interest => interestsMapping[interest.trim()] || interest.trim()).join('\n') : '',
  receiveMarketingEmails: marketingMapping[data.receiveMarketingEmails] || '',
});

// eslint-disable-next-line camelcase
const createWineLoverActivitiesTableData = (row_id, user_id, user_name, user_email, user_image, channel, role, registration_type, interests, marketing_enabled, activity_timestamp, activity_type, partner_name, menu_name, wine_name, rating) => ({ row_id, user_id, user_name, user_email, user_image, channel, role, registration_type, interests, marketing_enabled, activity_timestamp, activity_type, partner_name, menu_name, wine_name, rating });

const adaptWineLoverActivitiesTableData = (data) => {
  const dataList = [];
  data.forEach((item) => {
    const formattedInterests = item.interests ? item.interests.split(',').map(interest => interestsMapping[interest.trim()] || interest.trim()).join('\n') : '';
    dataList.push(
      createWineLoverActivitiesTableData(
        item.row_id,
        pad(item.user_id, 3),
        item.user_name,
        item.user_email,
        item.user_image,
        chanelMapping[item.channel] || item.channel,
        item.role,
        item.registration_type,
        formattedInterests,
        marketingMapping[item.marketing_enabled] || '',
        item.activity_timestamp?.value ? moment(item.activity_timestamp?.value).format('DD.MM.YYYY, HH:mm') : '',
        activityTypeMapping[item.activity_type] || item.activity_type,
        item.partner_name,
        item.menu_name,
        item.wine_name,
        item.rating
      )
    );
  });
  return dataList;
};

const adaptWineLoversActivitiesPartnerFilter = (data) => data.map(item => ({ value: item.partner_name, label: item.partner_name }));

const adaptWineLoversActivitiesMenuFilter = (data) => data.map(item => ({ value: item.menu_name, label: item.menu_name }));

const adaptWineLoversActivitiesWineFilter = (data) => data.map(item => ({ value: item.wine_name, label: item.wine_name }));

const adaptWineLoverActivityDetailsData = (data) => ({
  user_name: data.user_name,
  user_email: data.user_email,
  user_image: data.user_image,
  user_id: pad(data.user_id, 3),
  channel: chanelMapping[data.channel] || data.channel,
  role: data.role,
  registration_type: data.registration_type,
  interests: data.interests ? data.interests.split(',').map(interest => interestsMapping[interest.trim()] || interest.trim()).join('\n') : '',
  marketing_enabled: marketingMapping[data.marketing_enabled] || '',
  activity_timestamp: data.activity_timestamp?.value ? moment(data.activity_timestamp?.value).format('DD.MM.YYYY, HH:mm') : '',
  activity_type: activityTypeMapping[data.activity_type] || data.activity_type,
  partner_name: data.partner_name,
  menu_name: data.menu_name,
  wine_name: data.wine_name,
  rating: data.rating,
});

export {
  adaptUsersTableData,
  adaptSPTableData,
  adaptKiosksMenusTableData,
  adaptCataloguesTableData,
  serviceProvidersOptionData,
  adaptWineLabelsTableData,
  adaptWineLoversTableData,
  adaptWineLoverDetailsData,
  adaptWineLoverActivitiesTableData,
  adaptWineLoversActivitiesPartnerFilter,
  adaptWineLoversActivitiesMenuFilter,
  adaptWineLoversActivitiesWineFilter,
  adaptWineLoverActivityDetailsData
}
