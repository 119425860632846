import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

import CustomSearch from 'components/common/customSearchV2';
import CustomToolbarAction from 'components/common/customToolbarAction';

import M from 'messages';

import classes from './styles';

const CustomTableToolbar = (props) => {
  const {
    onSearchCallback,
    filteredParams,
    handleExport
  } = props;

  const searchValue = filteredParams?.params?.search?.value || '';

  return (
    <Toolbar sx={classes.root}>
      <Box sx={classes.filters}>
        <CustomSearch
          onSearchCallback={onSearchCallback}
          searchValue={searchValue}
        />
      </Box>
      <Box sx={classes.actions}>
        {handleExport && (
          <CustomToolbarAction
            handleOnClick={handleExport}
            btnText={M.get('actions.export')}
            tooltipText={M.get('actions.export')}
            startIcon={<GetAppIcon />}
          />
        )}
      </Box>
    </Toolbar>
  );
};

CustomTableToolbar.propTypes = {
  onSearchCallback: PropTypes.func.isRequired,
  filteredParams: PropTypes.instanceOf(Object).isRequired,
  handleExport: PropTypes.func,
};

export default CustomTableToolbar;