import { get } from 'services/client';
import { apiEndpoints, serverUrl } from 'config';

export default {
  getWineLoversActivities: async (params) => {
    const options = { url: apiEndpoints.wineLoversActivities, params: { ...params } };
    return get(options);
  },
  getWineLoversActivitiesPartnerFilter: async (params) => {
    const options = { url: apiEndpoints.wineLoversActivitiesPartnerFilter, params: { ...params } };
    return get(options);
  },
  getWineLoversActivitiesMenuFilter: async (params) => {
    const options = { url: apiEndpoints.wineLoversActivitiesMenuFilter, params: { ...params } };
    return get(options);
  },
  getWineLoversActivitiesWineFilter: async (params) => {
    const options = { url: apiEndpoints.wineLoversActivitiesWineFilter, params: { ...params } };
    return get(options);
  },
  getWineLoverActivity: async (id) => {
    const options = { url: `${apiEndpoints.wineLoversActivities}/${id}` };
    return get(options);
  },
  exportWineLoversActivities: async (params) => {
    window.open(`${serverUrl}/api${apiEndpoints.exportWineLoversActivities}?params=${JSON.stringify(params)}`, '_blank');
    return null;
  }
}