import UserName from "../commonCellComponent/userName/UserName";

const tableOptions = {
  wineLover: {
    fields: [
      { id: 'name', label: 'table.wineLover.user', component: UserName, type: 'customComponent', sortable: true, width: '15%', cellPaddingLeft: '24px' },
      { id: 'id', label: 'table.wineLoversActivities.id', type: 'text', sortable: true, width: '6%', textAlign: 'right', headAlign: 'right' },
      { id: 'userType', label: 'table.wineLover.userType', type: 'text', sortable: true, width: '10%' },
      { id: 'provider', label: 'table.wineLover.provider', type: 'text', sortable: true, width: '6%' },
      { id: 'country', label: 'table.wineLover.country', type: 'text', sortable: true, width: '6%' },
      { id: 'registrationType', label: 'table.wineLover.registrationType', type: 'text', sortable: true, width: '8%' },
      { id: 'interests', label: 'table.wineLover.interests', type: 'text', sortable: true, width: '15%' },
      { id: 'receiveMarketingEmails', label: 'table.wineLover.marketingToggle', type: 'text', sortable: true, width: '8%' },
      { id: 'createdAt', label: 'table.wineLover.registrationDate', type: 'text', sortable: true, width: '10%', textAlign: 'right', headAlign: 'right', },
      { id: 'lastLogin', label: 'table.wineLover.lastLoginDate', type: 'text', sortable: true, width: '10%', textAlign: 'right', headAlign: 'right', },
    ],
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    filterFields: [],
    searchFields: ['name', 'email']
  }
};

export default tableOptions;