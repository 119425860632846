import { makeAutoObservable, action, computed, toJS, extendObservable, set } from "mobx";

const defaultParams = {
  params: {
    sort: {
      field: "createdAt",
      order: "desc"
    },
    filter: {},
    limit: 100,
    skip: 0,
  }
}

class WineLovers {

  getDefaultStoreProps = () => ({
    wineLovers: {
      count: 0,
      data: []
    },
    filterData: defaultParams,
  });

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  @action
  setStoreWineLovers(newData) {
    this.wineLovers.data = [];
    newData.data.forEach(this.addStoreWineLovers);
    this.wineLovers.count = newData.count;
  }

  @action
  addStoreWineLovers = (element) => {
    this.wineLovers.data.push(element);
  }

  @action
  setFilter(filter) {
    this.filterData = filter || defaultParams;
  }

  @computed
  get filter() {
    const filterToJS = toJS(this.filterData)
    return filterToJS;
  }

}

export default new WineLovers();